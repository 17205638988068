import { Paper, Typography } from "@mui/material";
import ActivityChart from "../components/charts/ActivityChart";
import RetentionChart from "../components/charts/RetentionChart";
import MentalHealthImpactChart from "../components/charts/MentalHealthImpactChart";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { ObjectId, WithId } from "mongodb";
import BackendClient, { IAccount } from "../lib/BackendClient";


const Dashboard = () => {
    const [accounts, setAccounts] = useState<WithId<IAccount>[]>([]);
    const [selectedAccount, setSelectedAccount] = useState<ObjectId>();
    const [days, setDays] = useState<number>(30);
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
            const fetchAccounts = async () => {
                const data = await BackendClient.instance.accounts();
                setAccounts(data);
            }

            if(!loaded) {
                setLoaded(true);
                fetchAccounts();
            }
        }, [loaded]);



    return (
        <>
        <FormControl sx={{ minWidth: 120 }} size="small">
                    <InputLabel id="days-select-label">Account</InputLabel>
                    <Select
                        labelId="days-select-label"
                        id="days-select"
                        value={selectedAccount}
                        label="Account"
                        onChange={(e) => setSelectedAccount(e.target.value as ObjectId)}
                    >
                        <MenuItem key={0} value={undefined}>global</MenuItem>
                        {accounts.map(account => (
                            <MenuItem key={account._id.toString()} value={account._id.toString()}>{account.name}</MenuItem>
                        ))}
                    </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 120, marginBottom: 3, marginLeft: 2 }} size="small">
                <InputLabel id="days-select-label">Days</InputLabel>
                <Select
                    labelId="days-select-label"
                    id="days-select"
                    value={days}
                    label="Days"
                    onChange={(e) => setDays(Number(e.target.value))}
                >
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={14}>14</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={90}>90</MenuItem>
                    <MenuItem value={365}>365</MenuItem>
                </Select>
            </FormControl>

        <Paper sx={{padding: 3}}>
            <Typography component="h5" variant="h5" gutterBottom>
                Dashboard
            </Typography>
            <MentalHealthImpactChart account={selectedAccount} />


            <ActivityChart account={selectedAccount} days={days} />

            <RetentionChart account={selectedAccount} days={days} />


        </Paper>
</>
    )
}

export default Dashboard;

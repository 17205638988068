import { useState, useEffect } from "react";
import BackendClient from "../../lib/BackendClient";
import { XAxis, YAxis, ResponsiveContainer, Line, LineChart, Tooltip, CartesianGrid } from 'recharts';
import { Box, Card, Typography } from "@mui/material";
import { ObjectId } from "mongodb";

type RetentionData = {
  day: string;
  retentionRate: number;
};

const calculateRollingRetention = (users: { signupDate: string; lastActivityDate: string }[], days: number): RetentionData[] => {
    // remove users that are not registered for at least 'days' days
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const filteredUsers = users.filter(user => {
        const signupDate = new Date(user.signupDate);
        const totalDays = Math.floor((yesterday.getTime() - signupDate.getTime()) / (1000 * 3600 * 24));
        return totalDays >= days;
    });

    // console.log('filteredUsers', filteredUsers);

    const totalUsers = filteredUsers.length;
    const retentionCounts: number[] = [];

    for (let day = 0; day <= days; day++) {
        const retainedUsers = filteredUsers.filter(user => {
            const signupDate = new Date(user.signupDate);
            const lastActivityDate = new Date(user.lastActivityDate);
            const diffDays = Math.floor((lastActivityDate.getTime() - signupDate.getTime()) / (1000 * 3600 * 24));
            return diffDays >= day;
        }).length;

        retentionCounts.push((retainedUsers / totalUsers) * 100);
    }

    return retentionCounts.map((rate, index) => ({
        day: `Day ${index}`,
        retentionRate: parseFloat(rate.toFixed(2)),
    }));
};

const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
        return (
            <Card sx={{ p: 1 }}>
                <Typography variant="body2">{`${label} : ${payload[0].value}%`}</Typography>
            </Card>
        );
    }
    return null;
};

type Props = {
    account: ObjectId | undefined;
    days: number;
};

const RetentionChart = ({account, days}: Props) => {
    const [data, setData] = useState<RetentionData[]>([]);

    useEffect(() => {
        const fetch = async () => {
            const result = await BackendClient.instance.retention(account);
            const processedData = calculateRollingRetention(result, days);
            setData(processedData);
        };

        fetch();
    }, [days, account]);

    return (
        <Card sx={{ p: 3, mt: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" gutterBottom>
                    {days}-Day Rolling Retention Rate
                </Typography>

            </Box>
            <ResponsiveContainer width="100%" height={400}>
                <LineChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="day" />
                    <YAxis domain={[0, 100]} tickFormatter={(tick) => `${tick}%`} />
                    <Tooltip content={<CustomTooltip />} />
                    <Line type="basis" dataKey="retentionRate" stroke="#8884d8" dot={false} activeDot={{ r: 5 }} />
                </LineChart>
            </ResponsiveContainer>
        </Card>
    );
};

export default RetentionChart;

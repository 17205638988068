import { Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import BackendClient, { IFeedbackData } from "../lib/BackendClient";
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis } from 'recharts';

type ChartProps = {
    name?: string
    data: any
}

const TextFeedback = ({name, data}: ChartProps) => {
    if(!data || data.length < 1) return <></>;
    return (
        <div>
            <h1>{name}</h1>
            {data.map((v: string, i: number) => {
                return <p key={i}>{v}</p>
            })}
        </div>
    )
}

const Chart = ({name, data}: ChartProps) => {
    const [processedData, setProcessedData] = useState<any>([]);
    const [initialized, setInitialized] = useState<boolean>(false);

    if(!data || data.length < 1) return <></>;

    if(!initialized) {


    //console.log('data:', data);
    const d = [
        { name: '0 points', value: 0 },
        { name: '1 point', value: 0 },
        { name: '2 points', value: 0 },
        { name: '3 points', value: 0 },
        { name: '4 points', value: 0 },
    ];

    const max = Math.max(...data);
    console.log('max:', max);
    if(max > 4) {
        d.push({ name: '5 points', value: 0 });
        d.push({ name: '6 points', value: 0 });
        d.push({ name: '7 points', value: 0 });
        d.push({ name: '8 points', value: 0 });
        d.push({ name: '9 points', value: 0 });
        d.push({ name: '10 points', value: 0 });
    }

    for(const v of data) {
        if(d[v]) {
        d[v].value += 1;
        }
    }
    //console.log('d:', d);
    setProcessedData(d);
    setInitialized(true);
}

    return (
        <>
        <div>
            <h1>{name}</h1>
        </div>
        <ResponsiveContainer width="100%" height={300}>
            <BarChart width={150} height={40} data={processedData}>
                <XAxis dataKey="name" />
                <YAxis />
                <Bar dataKey="value" fill="#8884d8" />
            </BarChart>
        </ResponsiveContainer>
      </>
    )
}

const Feedback = () => {
    const [initialized, setInitialized] = useState<boolean>(false);
    const [data, setData] = useState<IFeedbackData>();

    useEffect(() => {
            const fetch = async () => {
                const result = await BackendClient.instance.userFeedback();
                setData(result);
                setInitialized(true)
            }

            if(!initialized) fetch()
        })

    return (
        <Paper sx={{padding: 3}}>
            <Typography component="h5" variant="h5" gutterBottom>
                Feedback
            </Typography>
            {initialized && data && <>
              <Chart name="Usability" data={data["1"]}/>
              <Chart name="Therapy Experience" data={data["3"]}/>
              <Chart name="Mental Health Improvement" data={data["4"]}/>
              <Chart name="Felt Heard & Respected" data={data["5"]}/>
              <Chart name="Could work on my topics" data={data["6"]}/>
              <Chart name="Recommend to Friend" data={data["nps"]}/>

              <TextFeedback name="Suggestions for Improvement" data={data["7"]}/>
              <TextFeedback name="NPS reasons (first)" data={data["2"]}/>
              <TextFeedback name="NPS reasons (second)" data={data["8"]}/>


            </>}



        </Paper>
    )
}

export default Feedback;

import React, { useState } from "react"
import BackendClient from "../lib/BackendClient"
import { XAxis, YAxis,ResponsiveContainer, Bar, BarChart, Tooltip } from 'recharts';
import { Paper, Typography } from "@mui/material";


const Topics = () => {
    const [initialized, setInitialized] = useState<boolean>(false)
    const [focusData, setFocusData] = useState<any>()
    const [questCategories, setQuestCategories] = useState<any>()


    React.useEffect(() => {
        const fetch = async () => {
            setInitialized(true)
            const resultFocus = await BackendClient.instance.focus()
            const mappedFocus = Object.keys(resultFocus).map((key) => ({category: key, value: resultFocus[key]}))
            setFocusData(mappedFocus)

            const resultQuestCategories = await BackendClient.instance.questCategories()
            setQuestCategories(resultQuestCategories)
        }

        if(!initialized) fetch()
    })

    return (
        <>
        <Paper sx={{padding: 3, marginBottom: 3}}>
        <Typography component="h5" variant="h5" gutterBottom>
            Focus
        </Typography>
            <ResponsiveContainer width="100%" height={300} >
            <BarChart data={focusData} >
                <XAxis dataKey="category" />
                <YAxis scale="linear"/>
                <Tooltip />
                <Bar dataKey="value" fill="#54a160" />
            </BarChart>
            </ResponsiveContainer>
        </Paper>

        <Paper sx={{padding: 3, marginBottom: 3}}>
        <Typography component="h5" variant="h5" gutterBottom>
        Quest Categories
        </Typography>
            <ResponsiveContainer width="100%" height={300} >
            <BarChart data={questCategories} >
                <XAxis dataKey="_id" />
                <YAxis scale="linear"/>
                <Tooltip />
                <Bar dataKey="count" fill="#5888d6" />
            </BarChart>
            </ResponsiveContainer>
        </Paper>
        </>
    )
}

export default Topics;

import React from "react"
import BackendClient, { IAccount} from "../lib/BackendClient"
import TextField from '@mui/material/TextField'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { WithId } from "mongodb"
import { useNavigate } from 'react-router-dom';
import { Box, Button, Paper, Typography } from "@mui/material"

const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', width: 250 },
    { field: 'credits', headerName: 'Credits', width: 150 },
    { field: 'users', headerName: 'Users', width: 150 },
    { field: 'nps', headerName: 'NPS Score', width: 150, valueGetter: (params) => {
        return params.row.nps.nps.toFixed(1);
    }},
    { field: 'nps.asked', headerName: 'Answers', width: 150, valueGetter: (params) => {
        return params.row.nps.totalUsersAsked;
    }},
    { field: 'nps.avg', headerName: 'Average', width: 150, valueGetter: (params) => {
        return (params.row.nps.totalPointsGiven / params.row.nps.totalUsersAsked).toFixed(1);
    }},

]


const Accounts = () => {
    const navigate = useNavigate();

    const [loaded, setLoaded] = React.useState<boolean>(false)
    const [accounts, setAccounts] = React.useState<WithId<IAccount>[]>([])
    const [adding, setAdding] = React.useState<boolean>(false)
    const [newAccountName, setNewAccountName] = React.useState<string>('')
    const [newAccountCredits, setNewAccountCredits] = React.useState<number>(0)


    const handleCellClick = (cell: any) => {
        console.log(cell);
        // Navigate to another page, e.g., account details page
        navigate(`/accounts/${cell.row._id}`);
    };

    const addNew = async () => {
        const result = await BackendClient.instance.createAccount(newAccountName, newAccountCredits)
        console.log(result);
        setAdding(false);
        setLoaded(false);
    }


    React.useEffect(() => {
        const fetch = async () => {
            const data = await BackendClient.instance.accounts();
            console.log(data);
            setAccounts(data);
        }

        if(!loaded) {
            setLoaded(true);
            fetch();
        }
    }, [loaded]);



    return (
        <Paper sx={{padding: 3}}>
            <Typography component="h5" variant="h5">
                Accounts
            </Typography>

            {!adding &&
            <>
            <Button
                onClick={() => setAdding(!adding)}
                style={{padding: 10, borderRadius: 10, margin: 10}}>
                    Create New
            </Button>

            <DataGrid
                rows={accounts}
                columns={columns}
                getRowId={(row) => row._id}
                onCellClick={handleCellClick}
            />

            </>

            }

            {adding &&
            <Box sx={{padding: 5}}>
            <TextField
                label="Name"
                variant="outlined"
                value={newAccountName}
                onChange={e => setNewAccountName(e.target.value)}
            />

            <TextField
                label="Credits"
                variant="outlined"
                type="number"
                value={newAccountCredits}
                onChange={e => setNewAccountCredits(parseInt(e.target.value))}
            />

            <Button
                color="primary"
                onClick={addNew} style={{padding: 10, borderRadius: 10, margin: 10}}>Create</Button>
            <Button
            color="error"
            onClick={() => setAdding(!adding)} style={{padding: 10, borderRadius: 10, margin: 10}}>Cancel</Button>

            </Box>
            }
        </Paper>
    )
}

export default Accounts

import React, { useState } from "react"
import { WithId } from 'mongodb'
import BackendClient, { IStat } from "../lib/BackendClient"
import { Paper, Typography } from "@mui/material";
import { DataGrid, GridColDef } from '@mui/x-data-grid';

const columns: GridColDef[] = [
    { field: 'date', headerName: 'Date', width: 100, valueFormatter: params => new Date(params?.value).toLocaleDateString(), },
    { field: 'requests', headerName: 'Requests', width: 100 },
    { field: 'promptTokens', headerName: 'Prompt', width: 100 },
    { field: 'completionTokens', headerName: 'Completion', width: 100 },
    { field: 'totalTokens', headerName: 'Total Tokens', width: 100 },
    { field: 'maxTpm', headerName: 'Max TPM', width: 100 },
    { field: 'maxRpm', headerName: 'Max RPM', width: 100 },
    { field: 'Costs', headerName: 'Costs', width: 150, valueGetter: (params) => {
        let estimate = params.row.promptTokens/1000 * 0.03
        estimate += params.row.completionTokens/1000 * 0.06
        return `$${estimate.toFixed(2)}`
    }}
]

const Costs = () => {
    const [initialized, setInitialized] = useState<boolean>(false)
    const [costs , setCosts] = useState<WithId<IStat>[]>([])
    const [costsPerRequest, setCostsPerRequest] = useState<number>(0)

    React.useEffect(() => {
        const fetch = async () => {
            setInitialized(true);
            const costs = await BackendClient.instance.costs();
            setCosts(costs.reverse());

            let totalCosts = 0;
            let totalRequests = 0;
            for(const cost of costs) {
                totalCosts += cost.promptTokens/1000 * 0.03;
                totalCosts += cost.completionTokens/1000 * 0.06;
                totalRequests += cost.requests;
            }
            setCostsPerRequest(totalCosts/totalRequests*100);
        }

        if(!initialized) fetch();
    })

    return (
        <>
            <Paper sx={{padding: 3, marginBottom: 3}}>
        <Typography component="h5" variant="h5" gutterBottom>
        API usage & costs
        </Typography>
        <Typography component="body" gutterBottom>Estimated costs per API request: ~{costsPerRequest.toFixed(2)} cents</Typography>
            <DataGrid
                rows={costs}
                columns={columns}
                getRowId={(row) => row._id.toString()}
            />
        </Paper>
        </>
    )
}

export default Costs;

import React, { useState } from "react"

import BackendClient, { IDashboardData } from "../../lib/BackendClient"


import { XAxis, YAxis,ResponsiveContainer, Bar, BarChart, Tooltip, CartesianGrid } from 'recharts';
import { Box, Card, LinearProgress, Typography } from "@mui/material";
import { ObjectId } from "mongodb";


const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Monate sind 0-basiert
    return `${day}.${month}.`;
};

type Props = {
    account: ObjectId | undefined;
    days: number;
};

const ActivityChart = ({account, days}: Props) => {
    //const [initialized, setInitialized] = useState<boolean>(false)
    const [data, setData] = useState<IDashboardData>()
    const [totalSignups, setTotalSignups] = useState<number>(0)

    React.useEffect(() => {
        const fetch = async () => {
            const result = await BackendClient.instance.stats(days, account);
            setData(result);

            // count sum of signups
            let sum = 0;
            result.signups.forEach(signup => {
                sum += signup.signups;
            })
            setTotalSignups(sum);

            //setInitialized(true)
        }

        //if(!initialized) fetch()
        fetch();
    }, [days, account]);

    return (
        <>
        <Card sx={{padding: 3, marginBottom: 3, display: 'flex', justifyContent: 'space-between'}}>
            <Box>

                <Typography variant="h6" gutterBottom>
                    Users
                </Typography>
                <Typography variant="h2" gutterBottom>
                    {data ? data.totalUsers : '...'}
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" gutterBottom>
                    Net Promoter Score®
                </Typography>
                <Typography variant="h2" gutterBottom>
                    {data ? data.nps.nps.toFixed(1) : '...'}%
                </Typography>
                <Typography variant="body2">
                    {data ? data.nps.totalUsersAsked : '...'} users, {data ? (data.nps.totalPointsGiven / data.nps.totalUsersAsked).toFixed(1) : '...'} average
                </Typography>
            </Box>

        </Card>
            <Card sx={{padding: 3, marginBottom: 3}}>
                <Typography variant="h6" gutterBottom>
                    {totalSignups} Signups within the last {days} days
                </Typography>
                {data &&
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={data?.signups}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" tickFormatter={formatDate} />
                        <YAxis />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="signups" fill="#bf5a0d" />
                    </BarChart>
                </ResponsiveContainer>
                }
                {!data && <LinearProgress style={{marginTop: 50, alignSelf: 'center'}} />}
            </Card>

        <Card sx={{padding: 3, marginBottom: 3}}>
            <Typography variant="h5" gutterBottom>
                Interactions within the last {days} days
            </Typography>
            <Typography variant="h6" gutterBottom>
                {data ? data.activeUsers : '...' } active users
                {data && data.totalUsers > 0 && <>&nbsp;
                ({Math.round(100 * 100 / data.totalUsers * data.activeUsers) / 100}%)
                </>}
            </Typography>

            <ResponsiveContainer width="100%" height={300}>
                <BarChart data={data?.usage}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" tickFormatter={formatDate} />
                    <YAxis />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="interactions" fill="#298044" />
                </BarChart>
            </ResponsiveContainer>
        </Card>
        </>
    )
}

export default ActivityChart

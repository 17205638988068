/* kept this file as a template for later use */
import { useState } from 'react';

import TextField from '@mui/material/TextField'

import Button from '@mui/material/Button'
import { FormControl, Stack } from '@mui/material'
import { Box } from "@mui/material"
import assets from '../assets'
import BackendClient from '../lib/BackendClient'

const Logo = () => <img src={assets.images.logoWithClaim} width={250} alt="ephoria" />

type LoginProps = {
    setAuthenticated: Function
}

const Login = (props: LoginProps) => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [tan, setTan] = useState("")
    const [tanSent, setTanSent] = useState(false)

    const handleSubmitUserAndPassword = async (e: any) => {
        e.preventDefault()
        const success = await BackendClient.instance.authenticate(email, password, '')
        if(success) {
           // no mtan required
            props.setAuthenticated(success)
        }
        setTanSent(true)
    }

    const handleSubmitTan = async (e: any) => {
      e.preventDefault()
      const success = await BackendClient.instance.authenticate(email, password, tan)
      props.setAuthenticated(success)
    }

    return (
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 5,
          }}
        >
        <Logo />


            <FormControl>
                <Stack sx={{gap: 4}}>
                  { !tanSent &&
                  <>
                    <TextField
                    label="Username"
                    required
                    onChange={e => setEmail(e.target.value)}
                    ></TextField>
                    <TextField
                    label="Password"
                    type='password' required
                    onChange={e => setPassword(e.target.value)}
                    onKeyDown={(e) => {
                      if(e.key === 'Enter') handleSubmitUserAndPassword(e)
                    }}
                    onSubmit={handleSubmitUserAndPassword}
                    ></TextField>
                    <Button onClick={handleSubmitUserAndPassword} variant="contained">Login</Button>
                  </>
                  }
                  { tanSent &&
                  <>
                    <TextField
                    label="mTAN"
                    required onChange={e => setTan(e.target.value)}
                    autoComplete='one-time-code'
                    autoFocus
                    ></TextField>
                    <Button onClick={handleSubmitTan} variant="contained">Weiter</Button>
                  </>
                  }
                </Stack>
            </FormControl>
        </Box>
    )
}

export default Login

import { Card, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import BackendClient from "../../lib/BackendClient";
import { ObjectId } from "mongodb";

export type ChartError = {
    error: string
}

const isChartError = (response: any): response is ChartError => {
    return (response as ChartError).error !== undefined;
}

type Props = {
    account: ObjectId | undefined;
};

const MentalHealthImprovementChart = ({account}: Props) => {
    const [data, setData] = useState<number | null>(null);
    const [error, setError] = useState<string>();

    useEffect(() => {
        // fetch data
        const fetch = async () => {
            const result = await BackendClient.instance.mentalHealthImprovement(account);
            console.log(result)
            // check if error or data is returned
            if (isChartError(result)) {
                setError(result.error);
                return;
            }
            setData(result as number);
            setError(undefined);
        }
        fetch();
    }
    , [account]);

    return <Card sx={{padding: 3, marginBottom: 3}}>
        <Typography variant="h6" gutterBottom>
                Mental Health Impact
        </Typography>
        { error && <Typography color="error">{error}</Typography> }

        { data && <Typography variant="h2" gutterBottom>{data.toFixed(0)}%</Typography> }
        </Card>
}

export default MentalHealthImprovementChart;

import Dashboard from "../pages/Dashboard";
import Feedback from "../pages/Feedback";
import Topics from "../pages/Topics";
import Costs from "../pages/Costs";
import Users from "../pages/Users";
import Accounts from "../pages/Accounts";
import Account from "../pages/Account";
import Dialog from "../pages/Dialog";
import Settings from "../pages/Settings";
import EventCategories from "../pages/EventCategories";

// https://mui.com/material-ui/material-icons/
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PersonIcon from '@mui/icons-material/Person';
import Content from "../pages/Content";
import ChatIcon from '@mui/icons-material/Chat';
import Business from "@mui/icons-material/Business";
import SettingsIcon from '@mui/icons-material/Settings';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import QuizIcon from '@mui/icons-material/Quiz';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import { RouteType } from "./config";

const appRoutes: RouteType[] = [
  {
    index: true,
    element: <Dashboard />,
    state: "dashboard"
  },
  {
    path: "/",
    element: <Dashboard />,
    state: "dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <MultilineChartIcon />
    },
  },
  {
    path: "/feedback",
    element: <Feedback />,
    state: "feedback",
    sidebarProps: {
      displayText: "Feedback",
      icon: <QuizIcon />
    },
  },
  {
    path: "/stats/events",
    element: <EventCategories />,
    state: "stats-events",
    sidebarProps: {
      displayText: "Events",
      icon: <BarChartIcon />
    },
  },
  {
    path: "/topics",
    element: <Topics />,
    state: "topics",
    sidebarProps: {
      displayText: "Topics",
      icon: <DashboardOutlinedIcon />
    }
  },
  {
    path: "/costs",
    element: <Costs />,
    state: "costs",
    sidebarProps: {
      displayText: "API Usage",
      icon: <AttachMoneyIcon />
    }
  },
  {
    path: "/accounts",
    element: <Accounts />,
    state: "accounts",
    sidebarProps: {
      displayText: "Accounts",
      icon: <Business />
    }
  },
  {
    path: "/accounts/:id",
    element: <Account />,
    state: "account",
  },
  {
    path: "/users",
    element: <Users />,
    state: "users",
    sidebarProps: {
      displayText: "Users",
      icon: <PersonIcon />
    }
  },
  {
    path: "/content",
    element: <Content />,
    state: "content",
    sidebarProps: {
      displayText: "Content",
      icon: <ChatIcon />
    }
  },
  {
    path: "/content/:directory/:file",
    element: <Dialog />,
    state: "content",
  },
  {
    path: "/content/:directory/:file/:chapter",
    element: <Dialog />,
    state: "content",
  },
  {
    path: "/settings",
    element: <Settings />,
    state: "settings",
    sidebarProps: {
      displayText: "Settings",
      icon: <SettingsIcon />
    }
  },

]

export default appRoutes